import React, { Component } from 'react'
import autoComplete from '@tarekraafat/autocomplete.js'
import SiteDate from './SiteDate'
import { navigate } from "@reach/router"  

const site_date = new SiteDate()
class Search extends Component {
  onLoad = () => {
    const map = new window.google.maps.Map(
      document.getElementById(this.props.id),
      this.props.options
    )
    this.props.onMount(map)
  }

  componentDidMount() {
    document
      .querySelector('#autoComplete')
      .addEventListener('init', function(event) {
        console.log(event)
      })

    // The autoComplete.js Engine instance creator
    const autoCompleteJS = new autoComplete({
      name: '貸株検索',
      selector: '#autoComplete',
      observer: false,
      data: {
        src: async () => {
          // Loading placeholder text
          document
            .querySelector('#autoComplete')
            .setAttribute('placeholder', 'Loading...')

          //for cache
          const cache_name = 'search_cache'
          const cache_data = JSON.parse(localStorage.getItem(cache_name))
          var cache_update = false
          if (!cache_data | cache_data == null){
              cache_update = true
          }
          else{
            const current_time = site_date.today() + site_date.hour()
            const cache_available = cache_data["body"]["cache_available"]
            console.log("current_time " + current_time)
            console.log("cache_available_time " + cache_available)
            console.log("cache update " + (cache_available <= current_time))
            if(cache_available <= current_time){
                cache_update = true
            }
          }
          if (cache_update) {
            // Fetch External Data Source
            console.log("fetch cache data")
            const source = await fetch(
              'https://api.rescala.jp/json/kashikabujp/search'
            )
            const data = await source.json()
            // Saves the fetched data into local storage
            localStorage.setItem(cache_name, JSON.stringify(data))
            // Retrieve the cached data from local storage
            const localData = JSON.parse(localStorage.getItem(cache_name))["body"]["data"]
            // Post Loading placeholder text
            document
              .querySelector('#autoComplete')
              .setAttribute('placeholder', autoCompleteJS.placeHolder)
            // Returns Fetched data
            return localData
          }
          // Post Loading placeholder text
          document
            .querySelector('#autoComplete')
            .setAttribute('placeholder', autoCompleteJS.placeHolder)
          return JSON.parse(localStorage.getItem(cache_name))["body"]["data"]
        },
        key: ['stock_code', 'stock_name'],
        cache: false,
        results: list => {
          // Filter duplicates
          const filteredResults = Array.from(
            new Set(list.map(value => value.match))
          ).map(food => {
            return list.find(value => value.match === food)
          })

          return filteredResults
        }
      },
      searchEngine: 'strict',
      placeHolder: '銘柄名又は銘柄コードを入力してください',
      maxResults: 5,
      sort: (a, b) => {
        if (a.match < b.match) return -1
        if (a.match > b.match) return 1
        return 0
      },
      highlight: true,
      debounce: 100,
      threshold: 1,
      trigger: {
        event: ['input', 'focus']
      },
      resultItem: {
        content: (data, element) => {
          // Modify Results Item Style
          element.style = 'display: flex; justify-content: space-between;'
          // Modify Results Item Content
          element.innerHTML = `
            <span style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden;">
                ${data.match}
            </span>
            <span style="display: flex; align-items: center; font-size: 13px; font-weight: 100; text-transform: uppercase; color: rgba(0,0,0,.2);">
                ${data.key}
            </span>`
        }
      },
      noResults: (dataFeedback, generateList) => {
        // Generate autoComplete List
        generateList(autoCompleteJS, dataFeedback, dataFeedback.results)
        // No Results List Item
        const result = document.createElement('li')
        result.setAttribute('class', 'no_result')
        result.setAttribute('tabindex', '1')
        result.innerHTML = `<span style="display: flex; align-items: center; font-weight: 100; color: rgba(0,0,0,.2);">Found No Results for "${dataFeedback.query}"</span>`
        document
          .querySelector(`#${autoCompleteJS.resultsList.idName}`)
          .appendChild(result)
      },
      feedback: data => {
        console.log(data)
      },
      onSelection: feedback => {
        document.querySelector('#autoComplete').blur()
        // Prepare User's Selected Value
        const selection = feedback.selection.value[feedback.selection.key]
        const stock_code = feedback.selection.value["stock_code"]
        console.log("onSelection is " + selection)
        console.log("stock_code is " + stock_code)

        // Render selected choice to selection div
        //document.querySelector(".selection").innerHTML = selection;
        // Replace Input value with the selected value
        document.querySelector('#autoComplete').value = selection
        //this.routingFunction(2438)
        //window.location.assign("/search?stock_code=" + stock_code);
        navigate("/search/?stock_code=" + stock_code)
        // Console log autoComplete data feedback
        //console.log(feedback);
      }
    })
    // autoComplete.unInit();
  }

  render() {
    return (
      <div id="search" style={{ textAlign: 'center' }}>
        <div className="autoComplete_wrapper">
          <input id="autoComplete" type="text" dir="ltr"></input>
        </div>
      </div>
    )
  }
}

export default Search