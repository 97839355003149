import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "section": "lastedPosts"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ContainerWrapper = makeShortcode("ContainerWrapper");
const ScrollAnimation = makeShortcode("ScrollAnimation");
const AllPosts = makeShortcode("AllPosts");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ContainerWrapper sx={{
      background: theme => theme.colors['backgroundPost']
    }} mdxType="ContainerWrapper">
      <ScrollAnimation animateIn="fadeIn" mdxType="ScrollAnimation">
        <h2>{`Lasted Posts`}</h2>
        <AllPosts numOfPosts={4} showSearchBar={false} mdxType="AllPosts" />
      </ScrollAnimation>
    </ContainerWrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      