import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "aboutImage": "about-image1.jpg"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ContainerWrapper = makeShortcode("ContainerWrapper");
const ScrollAnimation = makeShortcode("ScrollAnimation");
const AboutLayout = makeShortcode("AboutLayout");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ContainerWrapper sx={{
      background: theme => theme.colors['backgroundAbout']
    }} mdxType="ContainerWrapper">
      <ScrollAnimation animateIn="fadeIn" animateOnce mdxType="ScrollAnimation">
        <AboutLayout aboutImageFluid={props.imagesFluid['about-image1.jpg']} mdxType="AboutLayout">
  <h2>Title About</h2>
  <p>
    Description feature Lorem Ipsum is simply dummy text of the printing and
    typesetting industry. Lorem Ipsum has been the industry's standard dummy
    text ever since the 1500s, when an unknown printer took a galley of type and
    scrambled it to make a type specimen book. It has survived not only five
    centuries, but also the lea
  </p>
        </AboutLayout> 
      </ScrollAnimation>
    </ContainerWrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      