import baseTheme from 'gatsby-theme-adwordpress-mdx/src/gatsby-plugin-theme-ui'



export default {
  ...baseTheme,
  colors: {
    ...baseTheme.colors,
    modes: {
      ...baseTheme.colors.modes,
      aska: {
        textSecondary: 'hsl(0, 0%, 29%)',
        black: 'hsl(0, 0%, 4%)',
        text: '#000B00',
        background: 'hsl(0, 0%, 100%)',
        primary: '#000B00',
        secondary: '#AD7984',
        muted: 'hsl(0, 0%, 96%)',
        backgroundNavBar: '#FFFFFF',
        backgroundSideBar: '#FFFFFF',
        activeTextBar: 'hsl(0, 0%, 29%)',
        textBar: 'hsl(0, 0%, 29%)',
        backgroundCard: 'hsl(0, 0%, 96%)',
        shadowCard: '9px 8px 50px rgba(32,32,35,.1)',
        imgShadow: '6px 7px 20px 0px rgb(43, 43, 47)'
      },
    }
  },
  logo: {
    width: '4em'
  }
}
