import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { Pie, Line } from 'react-chartjs-2'
import { useQueryParam, NumberParam, StringParam } from 'use-query-params'
import {MyAdCard} from './MyAdCard'
interface IState {
  isLoaded: Boolean
  status: string
  items: JSON
}

interface HeadProps {
  update_date: string
  stock_name: string
  stock_code: string
}
interface GeneralProps {
  stock_price: string
  kashikabu_interest_rate: string
  reverse_daily_rate: string
}

interface KashikabuProps {
  rakuten_interest_rate: string
  sbi_interest_rate: string
  gmo_interest_rate: string
}

function dateFormat(s: string) {
  const str = String(s)
  const arr = (
    str.substr(0, 4) +
    '/' +
    str.substr(4, 2) +
    '/' +
    str.substr(6, 2)
  ).split('/')
  return `${arr[0]}年${arr[1]}月${arr[2]}日`
}

const HeadInfo = (props: HeadProps) => (
  <section id="head_info" className="block">
    <div id="stock_name" className="mid_bold">
      {props.stock_name}の貸株・逆日歩情報
    </div>
    <div id="update_date" className="small_bold">
      更新日: {dateFormat(props.update_date)}
    </div>
    <MyAdCard />
  </section>
)

const GeneralInfo = (props: GeneralProps) => (
  <section id="general_info" className="block">
    <section className="box_title">
      <h2>銘柄情報</h2>
    </section>
    <section className="box_row">
      <section className="small_box">
        <div className="small_box_key">基準価格</div>
        <div id="stock_price" className="small_box_value">
          {props.stock_price} 円
        </div>
      </section>
      <section className="small_box">
        <div className="small_box_key">最大貸株金利(年率)</div>
        <div id="kashikabu_interest_rate" className="small_box_value">
          {props.kashikabu_interest_rate} %
        </div>
      </section>
      <section className="small_box">
        <div className="small_box_key">逆日歩金利(年率)</div>
        <div id="reverse_daily_rate" className="small_box_value">
          {props.reverse_daily_rate} %
        </div>
      </section>
    </section>
  </section>
)

const KashikabuInfo = (props: KashikabuProps) => (
  <section id="general_info" className="block">
    <section className="box_title">
      <h2>貸株金利情報(年率)</h2>
    </section>
    <section className="box_row">
      <section className="small_box">
        <div className="small_box_key">楽天証券</div>
        <div id="rakuten_interest_rate" className="small_box_value">
          {props.rakuten_interest_rate} %
        </div>
      </section>
      <section className="small_box">
        <div className="small_box_key">SBI証券</div>
        <div id="sbi_interest_rate" className="small_box_value">
          {props.sbi_interest_rate} %
        </div>
      </section>
      <section className="small_box">
        <div className="small_box_key">GMOクリック証券</div>
        <div id="gmo_interest_rate" className="small_box_value">
          {props.gmo_interest_rate} %
        </div>
      </section>
    </section>
  </section>
)

function urlGetParam(Component) {
  /**
   * Get url param
   * @param props
   */
  const param = function(props) {
    const [stock_code, setNum] = useQueryParam('stock_code', NumberParam)
    return <Component {...props} stock_code={stock_code} />
  }
  return param
}

class SearchDetail extends React.Component<{}, IState> {
  readonly state = { isLoaded: false, status: 'init', items: JSON.parse('{}') }

  componentDidMount() {
    console.log(this.props.stock_code)
    const url = `https://api.rescala.jp/json/detail?stock_code=${this.props.stock_code}`
    console.log('request to ' + url)
    fetch(url)
      .then(res => res.json())
      .then(
        result => {
          console.log('fetch success')
          const j = JSON.parse(JSON.stringify(result))
          this.setState({
            isLoaded: true,
            status: j['status'],
            items: j['body']
          })
        },
        error => {
          console.log('error')
          this.setState({
            isLoaded: false,
            status: 'error',
            items: JSON.parse('{}')
          })
        }
      )
  }

  render() {
    console.log('state is ' + this.state.body)
    if (this.state.status == 'error') {
      console.log('http error')
      return <div>エラーが発生しました。</div>
    } else if (!this.state.isLoaded) {
      console.log('loading...')
      return <div>読み込み中です...</div>
    } else {
      console.log('http success')
      let result = this.state.items
      console.log(result)
      const get_kashikabu_interest_rate = () => {
        if (result['rakuten_interest_rate_is_max']) {
          return result['rakuten_interest_rate']
        }
        if (result['gmo_interest_rate_is_max']) {
          return result['gmo_interest_rate']
        }
        if (result['sbi_interest_rate_is_max']) {
          return result['sbi_interest_rate']
        }
      }
      return (
        <>
          <HeadInfo
            update_date={result.kashikabu_target_date}
            stock_code={result.stock_code}
            stock_name={result.stock_name}
          />
          <GeneralInfo
            stock_price={result.stock_price}
            kashikabu_interest_rate={(
              get_kashikabu_interest_rate() * 100.0
            ).toFixed(2)}
            reverse_daily_rate={(result.reverse_daily_rate * 100.0).toFixed(2)}
          />
          <KashikabuInfo
            rakuten_interest_rate={(
              result.rakuten_interest_rate * 100.0
            ).toFixed(2)}
            sbi_interest_rate={(
              result.sbi_interest_rate * 100.0
            ).toFixed(2)}
            gmo_interest_rate={(
              result.gmo_interest_rate * 100.0
            ).toFixed(2)}
          />
        </>
      )
    }
  }
}

export default urlGetParam(SearchDetail)
