export default class SiteDate {
  today() {
    const today = new Date()
    const y = today.getFullYear()
    const m = ('00' + (today.getMonth() + 1)).slice(-2)
    const d = ('00' + today.getDate()).slice(-2)
    return y + m + d
  }

  yesterday() {
    const today = new Date()
    const yesterday = new Date(today)
    yesterday.setDate(yesterday.getDate() - 1)
    const y = yesterday.getFullYear()
    const m = ('00' + (yesterday.getMonth() + 1)).slice(-2)
    const d = ('00' + yesterday.getDate()).slice(-2)
    return y + m + d
  }

  tomorrow() {
    const da = new Date()
    da.setDate(da.getDate() + 1)
    const y = da.getFullYear()
    const m = ('00' + (da.getMonth() + 1)).slice(-2)
    const d = ('00' + da.getDate()).slice(-2)
    return y + m + d
  }

  hour() {
    const date = new Date();
    const currentHours = ("0" + date.getHours()).slice(-2);
    return currentHours
  }
}
