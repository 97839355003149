import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "section": "footer"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Div = makeShortcode("Div");
const ScrollAnimation = makeShortcode("ScrollAnimation");
const SocialLink = makeShortcode("SocialLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Div sx={{
      background: theme => theme.colors['backgroundFooter']
    }} mdxType="Div">
      <ScrollAnimation animateIn="fadeIn" mdxType="ScrollAnimation">
        <Div sx={{
          display: "flex",
          justifyContent: "space-around",
          flexWrap: 'wrap',
          flexDirection: ['column', 'column', 'row'],
          px: 4
        }} mdxType="Div">
          <div>
            <h4> Contact us </h4>
            <h5> E-mail </h5>
            <p>yo-maruya@rescala.jp</p>
          </div>
          <Div sx={{
            display: 'flex'
          }} mdxType="Div">
  {
              /* svg code: http://svgicons.sparkk.fr/ */
            }
  <SocialLink href={'https://twitter.com/bc_kashikabu'} name={'icon'} iconPath={'M14.467,6.707c-0.34,0.198-0.715,0.342-1.115,0.419c-0.318-0.335-0.775-0.545-1.279-0.545c-0.969,0-1.754,0.773-1.754,1.727c0,0.135,0.015,0.267,0.045,0.394C8.905,8.628,7.612,7.94,6.747,6.896C6.596,7.151,6.509,7.448,6.509,7.764c0,0.599,0.31,1.128,0.78,1.438C7.002,9.192,6.732,9.115,6.495,8.985c0,0.007,0,0.014,0,0.021c0,0.837,0.605,1.535,1.408,1.694c-0.147,0.04-0.302,0.06-0.462,0.06c-0.113,0-0.223-0.011-0.33-0.031c0.223,0.687,0.871,1.186,1.638,1.199c-0.6,0.464-1.356,0.739-2.179,0.739c-0.142,0-0.281-0.007-0.418-0.023c0.777,0.489,1.699,0.775,2.689,0.775c3.228,0,4.991-2.63,4.991-4.913c0-0.075-0.002-0.149-0.004-0.223c0.342-0.244,0.639-0.547,0.875-0.894c-0.316,0.137-0.652,0.23-1.008,0.272C14.057,7.448,14.336,7.11,14.467,6.707 M10,0.594c-5.195,0-9.406,4.211-9.406,9.406c0,5.195,4.211,9.406,9.406,9.406c5.196,0,9.407-4.211,9.407-9.406C19.406,4.805,15.195,0.594,10,0.594 M10,18.552c-4.723,0-8.551-3.829-8.551-8.552S5.277,1.449,10,1.449c4.723,0,8.551,3.829,8.551,8.551S14.723,18.552,10,18.552'} mdxType="SocialLink" />
          </Div>
        </Div>
        <Div sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 3
        }} mdxType="Div">
  © {new Date().getFullYear()} 貸株.jp powered by gatsby
        </Div>
      </ScrollAnimation>
    </Div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      