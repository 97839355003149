import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Search from "../../components/Search";
import styles from '../../css/search.css';
import logo from "../../images/logo.svg";
export const _frontmatter = {
  "imagesFluid": ["image2.png", "image1.jpg"]
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ContainerWrapper = makeShortcode("ContainerWrapper");
const ScrollAnimation = makeShortcode("ScrollAnimation");
const MyAdCard = makeShortcode("MyAdCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <ContainerWrapper sx={{
      background: theme => theme.colors['backgroundPost']
    }} mdxType="ContainerWrapper">
      <ScrollAnimation animateIn="fadeIn" mdxType="ScrollAnimation">
        <img src={logo} alt="Logo" style={{
          "width": "50%",
          "height": "50%",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto"
        }} />
        <Search mdxType="Search" />
        <MyAdCard {...props} mdxType="MyAdCard" />
      </ScrollAnimation>
    </ContainerWrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      