import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Feature1 from './feature1.mdx';
import Feature2 from './feature2.mdx';
import Feature3 from './feature3.mdx';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ContainerWrapper = makeShortcode("ContainerWrapper");
const ScrollAnimation = makeShortcode("ScrollAnimation");
const FeaturesWrapper = makeShortcode("FeaturesWrapper");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <ContainerWrapper sx={{
      background: theme => theme.colors['backgroundFeatures']
    }} mdxType="ContainerWrapper">
  <ScrollAnimation animateIn="fadeIn" mdxType="ScrollAnimation">
    <FeaturesWrapper mdxType="FeaturesWrapper">
      <Feature1 {...props} mdxType="Feature1" />
      <Feature2 {...props} mdxType="Feature2" />
      <Feature3 {...props} mdxType="Feature3" />
    </FeaturesWrapper>
  </ScrollAnimation>
    </ContainerWrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      