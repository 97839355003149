import Footer from "../../../../src/sections/footer/footer.mdx";
import Features from "../../../../src/sections/features/features.mdx";
import About from "../../../../src/sections/about/about.mdx";
import General from "../../../../src/sections/general/general.mdx";
import LastedPosts from "../../../../src/sections/lastedPosts/lastedPosts.mdx";
import Header from "../../../../src/sections/header/header.mdx";
import css_search from "../../../../src/css/search.css";
import css_search_page from "../../../../src/css/search_page.css";
import css_main from "../../../../src/css/main.css";
import * as React from 'react';
export default {
  Footer,
  Features,
  About,
  General,
  LastedPosts,
  Header,
  css_search,
  css_search_page,
  css_main,
  React
};