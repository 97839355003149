import React from "react";
import Search from "./Search"
import SearchDetail from "./SearchDetail"

import { useQueryParam, NumberParam, StringParam } from "use-query-params";

const SearchPage = () => {
  // something like: ?x=123&foo=bar in the URL
  const [stock_code, setNum] = useQueryParam("stock_code", NumberParam);

  var search;
  console.log("stock_code is " + stock_code)
  if (stock_code) {
    search = <SearchDetail /> 
  }
  else{
    search = <Search />
  }

  return (
    <>
      <h1>貸株検索</h1>
      {search}
    </>
  );
};

export default SearchPage;